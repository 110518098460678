@use '@angular/material' as mat;
@import 'theme-helper';

@mixin noUI-slider-theme($theme) {
  $primary: get-primary($theme);

  .noUi-target[disabled=true] {
    .noUi-handle {
      border-color: grey;
      background-color: lightgrey;
    }
  }

  .noUi-handle {
    border-color: mat.get-color-from-palette($primary);
    background-color: mat.get-color-from-palette($primary, 50);
  }

  .noUi-tooltip {
    color: mat.get-color-from-palette($primary, 500);
  }

  .noUi-base {
    background-color: mat.get-color-from-palette($primary, 100);
  }

  .noUi-connect {
    background-color: mat.get-color-from-palette($primary);
  }
}
