@use '@angular/material' as mat;
@import 'theme-helper';

@mixin app-theme($theme) {
  $primary: get-primary($theme);

  .body {
    color: mat.get-color-from-palette($primary, A700);
    background-color: mat.get-color-from-palette($primary, 50);
  }

}
