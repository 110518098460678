@import "variables";

.xln {
  /*
 mat-accordion
  */

  .mat-accordion {
    margin-top: 0;

    .mat-expansion-panel {
      margin-top: 30px;
      margin-bottom: 30px;
      box-shadow: none;
      border-width: 2px;
      border-style: solid;

      &:not([class*=mat-elevation-z]) {
        border-radius: 0;
        box-shadow: none;
      }

    }

    .mat-expansion-panel.no-border {
      border: none;
    }

    & > .mat-expansion-panel:first-child,
    & > :first-child .mat-expansion-panel {
      margin-top: 0;
    }
  }

  .mat-expansion-panel-header {
    &.big-indicator .mat-expansion-indicator::after {
      padding: 4px;
      border-width: 0 3px 3px 0;
    }

    &.small-indicator .mat-expansion-indicator::after {
      padding: 2px;
      border-width: 0 1px 1px 0;
    }

  }

  .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header.custom-hover:not([aria-disabled='true']):hover {
    background-color: inherit;
  }

  /*
  stepper
  */

  .mat-stepper-horizontal {
    .mat-horizontal-stepper-header-container {
      display: none !important;
    }
  }

  /*
  mat-cards
   */

  mat-card.mat-card:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  /*
  paginator
   */

  .mat-paginator {
    .mat-paginator-container {
      justify-content: center;
    }
  }

  /*
  close-dialog-btn
   */

  .close-btn {
    mat-icon {
      border-radius: 50%;
      transition: transform $default-transition-time $default-transition-function;
      box-sizing: content-box;
    }

    &.close-dialog {
      position: absolute;
      right: -12px;
      top: -12px;
      z-index: 1;

      &:hover:enabled mat-icon {
        transform: rotate(90deg);
      }

      &_only-content {
        right: -4px;
        top: 14px;
      }
    }

  }

  /*
  mat-progress-bar
   */

  .mat-progress-bar.with-border {
    height: 9px;
    border-width: 1px;
    border-style: solid;

    .mat-progress-bar-background {
      fill: #ffffff;
    }

    .mat-progress-bar-buffer {
      background-color: #ffffff;
    }
  }

  /*
  mat tab
   */

  .mat-tab-group {

    mat-tab-header.mat-tab-header {
      border-bottom: none;
    }

    .mat-tab-body .mat-tab-body-content {
      overflow: hidden;
    }

    .mat-tab-body-active > .mat-tab-body-content {
      overflow: auto;
    }

    .mat-tab-label-content {
      font-size: 14px;
    }

    .mat-tab-label {
      min-width: 100px;
      font-weight: 400;
      opacity: 1;
    }
  }

  nav.menu-tab.mat-tab-nav-bar {
    border-bottom: none;
    margin-left: -16px;

    .mat-tab-link {
      position: relative;
      padding: 0 16px;
      min-width: 0;
      opacity: 1;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;

      &.active {
        font-weight: 600;
      }

      &.active:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 16px;
        right: 16px;
        box-shadow: inset 0 -2px;
      }

    }
  }

  .profile-tabs.mat-tab-group {
    .mat-tab-header {
      border-bottom-width: 1px;
      border-bottom-style: solid;
    }

    .mat-tab-label {
      margin: 0 16px;
      padding: 0;
    }

    .mat-tab-label {
      min-width: 0;
    }

    .mat-tab-body-content {
      padding: 48px 70px;
    }
  }

  /*
  matbadge
   */

  .mat-badge {
    & .mat-badge-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.mat-badge-circle.mat-badge-medium .mat-badge-content {
      $size: 20px;
      height: $size;
      min-width: $size;
      max-width: 34px; /* 3 symbols max*/
      width: auto;
      padding: 0 6px;
      border-radius: $size;
    }

    &.mat-badge-circle.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
      right: auto;
      left: calc(100% - 20px);
    }

    &.mat-badge-circle.mat-badge-medium.mat-badge-below .mat-badge-content {
      bottom: 0;
    }

    &.mat-badge-small .mat-badge-content {
      $size: 16px;
      height: $size;
      min-width: $size;
      max-width: 28px; /* 3 symbols max*/
      width: auto;
      padding: 0 4px;
      border-radius: $size;
      font-size: 10px;
      font-weight: 100;
    }

    &.mat-badge-small.mat-badge-below .mat-badge-content {
      bottom: -4px;
    }

    &.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
      right: auto;
      left: calc(100% - 8px);
    }
  }

  /*
  small, big select
   */

  .small-mat-select {
    .mat-form-field-infix {
      border: 0;
    }

    .mat-form-field-wrapper {
      padding: 0;
    }
  }

  .small-mat-select,
  .big-mat-select {
    .mat-select-value {
      max-width: 100%;
      width: auto;
    }

    .mat-form-field-infix {
      width: auto;
    }

    &.mat-form-field-appearance-legacy .mat-form-field-underline {
      visibility: hidden;
    }

    .mat-select-arrow {
      display: inline-block;
      margin: 3px 7px;
      padding: 2px;
      transform: rotate(45deg);
      border-right: 1px solid;
      border-left: none;
      border-bottom: 1px solid;
      border-top: none;
    }
  }

  .small-mat-select {
    .mat-select {
      display: flex;
      justify-content: center;
    }
  }

  .big-mat-select {
    .mat-select-value {
      font-size: 20px;
      font-weight: 600;
    }

    .mat-select-arrow {
      margin-left: 14px;
      padding: 4px;
      border-right: 3px solid;
      border-bottom: 3px solid;
    }

    &.mat-form-field-type-mat-select .mat-form-field-label {
      font-size: 17px;
    }

    &.mat-form-field-hide-placeholder .mat-select-placeholder {
      font-size: 17px;
    }
  }

  /*
  mat-chip-list
   */

  .mat-chip-list-wrapper {
    margin: 0;
  }

  .mat-chip-list-wrapper input.mat-input-element {
    margin: 0;
  }

  /*
  button
   */

  .mat-raised-button,
  .mat-flat-button,
  .mat-stroked-button {
    font-size: 12px;
    border-radius: 4px;
  }

  button.mat-mini-fab:not([class*=mat-elevation-z]),
  button.mat-fab:not([class*=mat-elevation-z]) {
    box-shadow: none;
  }

  .mat-raised-button,
  .mat-flat-button {
    padding: 2px 24px;
  }

  /*
  fake disable
   */

  .mat-form-field.fake-disable {
    .mat-icon-button[disabled] {
      color: inherit;
    }

    &.mat-form-field-disabled .mat-form-field-underline {
      background-size: 304% 100%;
    }

    .mat-input-element:disabled {
      color: inherit;
    }
  }

  /*
  mat menu
   */

  div.mat-menu-panel {
    max-width: 420px;
    max-height: 600px;
    min-height: 54px;
  }

  .tpt-more-menu {
    width: 157px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    &.card-menu {
      width: unset;
    }

  }

  .tpt-more-menu.priority-menu {

    .mat-menu-content:not(:empty) {
      padding-bottom: 8px;
    }

  }


  /*
  .profile-tabs inputs
   */

  .profile-tabs {
    font-size: 14px;

    .mat-form-field.mat-form-field-appearance-outline {
      .mat-form-field-flex {
        margin: 0;
        padding-left: 16px;
      }

      .mat-form-field-infix {
        padding: 12px 0;
        border: none;

      }

      .mat-form-field-outline {
        top: 0;
      }

      textarea.mat-input-element {
        padding: 6px 0;
      }

      .mat-select-arrow-wrapper {
        transform: none;
      }
    }
  }


  /**
  toggle-group
   */
  .mat-button-toggle-group-appearance-standard.custom-toggle-group {
    border-radius: 0;

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
      min-width: 130px;
      padding: 0 22px;
      line-height: 30px;
    }
  }

  .mat-table th,
  .mat-table td {
    vertical-align: middle;
  }

  @include mobile-down {


    .profile-tabs.mat-tab-group {
      .mat-tab-body-content {
        padding: 10px;
      }
    }

    .mat-button-toggle-group-appearance-standard.custom-toggle-group {
      .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        min-width: auto;
        padding: 0 12px;
        line-height: 30px;
      }
    }
  }
}

/*
mat-dialog
 */

.mat-dialog-container {
  border-radius: 8px !important;
}


/*
maticon
 */

.mat-icon {
  overflow: visible !important;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.04) !important;
}

.mat-select-value {
  vertical-align: middle;
}

.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start {
  border-width: 1px !important;
}

.subhead {
  font-weight: bold;
  font-size: 18px;
  color: $gray;
}

.subhead-1 {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $gray-darker;
}

.regular-18-24 {
  font-size: 18px;
  line-height: 24px;
  color: $gray-darker;
  font-weight: 400;
}

.regular-16-24 {
  font-size: 16px;
  line-height: 24px;
  color: $gray-dark;
  font-weight: 400;
}

.regular-14-20 {
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  font-weight: 400;
}

.caption {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: $gray-600;
}

.headline {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $gray;
}

.headline-18-24 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  color: $gray-darker;
}

.heading {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: $gray-darker;

  @include mobile-down {
    font-size: 20px;
    line-height: 28px;
  }
}

.regular {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
}

.gray-light {
  color: $gray-light;
}

.operation-type-select {
  min-width: unset !important;
  position: relative;
  top: 30px;
  left: 30px;

  mat-option.mat-option {
    height: 36px;
    font-size: 12px;
  }
}

.custom-select-36 {
  position: relative;
  top: 36px;
  left: 16px;
  min-width: 100% !important;

  mat-option.mat-option {
    height: 40px;
  }
}

.custom-dropdown-58 {
  position: relative;
  top: 52px;
  left: -4px;
  min-width: calc(100% + 40px) !important;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;


  mat-option.mat-option {
    height: 44px;
  }
}

.project-select.mat-select-panel {
  position: relative;
  top: 50px;
  left: 16px;
  min-width: 100% !important;

  mat-option.mat-option {
    height: unset;
    padding: 8px 20px;
  }

  .mat-option-text.mat-option-text {
    white-space: normal;
  }
}

.chat-dialog-align {
  transform: translateX(170px);
}

.chat-user-info-align {
  transform: translateX(170px);
}

.mat-selection-list {
  outline: none;
}

.default-snackbar {
  padding: 0 !important;
  box-shadow: none !important;

  @include mobile-down {
    margin-bottom: 40px !important;
  }
}
.small-snackbar {
  min-width: unset !important;
}

div.mat-menu-panel.bdc-walk-popup {
  border-radius: 2px !important;
  box-shadow: 0 8px 16px rgb(0 0 0 / 15%) !important;

  .container {
    padding: 24px !important;
  }

  .title .header {
    background: unset;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 28px !important;
    color: $text_00 !important;
  }

  .tour-text {
    color: $text_02;

    .link {
      color: $blue;
    }
  }

  .buttons {
    margin-bottom: 0 !important;
    margin-top: 24px !important;

    button {
      border-radius: 4px;
      background: $green-btn;
      color: $white !important;
      font-size: 16px;
      font-weight: 400;
      height: 36px;
      border: unset;
      width: 115px;
    }
  }

}

.mat-autocomplete-panel {
  max-height: 240px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.mat-input-element {
  caret-color: $gray-dark !important;
  box-sizing: border-box;
}

.menu-overlay {
  width: 360px;
  transform: translateY(-12px);
  box-shadow: 0 8px 8px rgba(8, 9, 78, 0.05), 0 0 2px rgba(8, 9, 78, 0.05) !important;
}

.green-checkbox.mat-checkbox {
  &.mat-checkbox-checked {
    .mat-checkbox-background,
    .mat-checkbox-ripple .mat-ripple-element {
      background-color: $green-main !important;
    }
  }

  &:not(.mat-checkbox-disabled) .mat-checkbox-ripple .mat-ripple-element {
    background-color: $green-main !important;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }
}

.contact-mobile-dialog {
  max-width: unset !important;

  .mat-icon {
    color: white !important;
  }
}

.custom-select-search {
  max-height: 332px !important;
  position: relative;
  top: 0;

  &.mat-select-panel {
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1), 0 4px 8px rgba(0, 0, 0, 0.1) !important;
  }

  &.search {
    max-width: 254px;
  }

  &.dialog {
    max-width: 200px;

    .mat-option-text {
      font-size: 14px;
    }
  }

  &.settings {
    max-width: 275px;
  }

  .mat-option {
    font-size: 16px !important;
  }
}

.filter-dialog, .pay-sbp-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.help-center-dialog {
  .mat-dialog-container {
    @include mobile-down {
      padding: 16px;
    }
  }

  @include mobile-down {
    margin-top: 0 !important;
    height: var(--app-height) !important;
  }
}

nouislider::ng-deep {
  .noUi-connect {
    background-color: $green-main;
  }

  .noUi-handle {
    box-shadow: unset;
    background-color: $green-main;
    border-color: $green-main;
  }

  .noUi-tooltip {
    color: $green-main;
  }
}

.header__menu {
  width: 200px;

  &.creative-objects {
    box-shadow: 0 2px 4px -1px rgba(161, 144, 254, 20%), 0 4px 5px 0 rgb(161, 144, 254, 14%), 0 1px 10px 0 rgb(161, 144, 254, 12%);
  }
}

.mat-menu-panel.creative-objects {
  .mat-menu-item:disabled {
    opacity: 0.6;
  }
}

.mat-menu-panel {
  min-height: 48px !important;
  max-width: 420px !important;
}


.mat-menu-item {
  font-size: 14px !important;
  color: $gray-900 !important;
  line-height: 44px !important;
  height: 44px !important;

  &.red-item {
    color: #F04438 !important;
  }

  &.menu-item-delete {
    color: $warning-red;

    &:hover {
      color: $warning-red;
      transition: all 300ms;
    }
  }

  &:hover {
    transition: all 300ms;
    background: $gray-100 !important;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      background: $white !important;
    }
  }
}

.header-profile-menu {
  .mat-menu-item {
    font-size: 16px !important;
  }
}

.more-menu.creative-objects {
  box-shadow: $co-box-shadow;
}

input.country-search {
  outline: none;
}

.country-list-button {
  font-size: 14px !important;
  color: $gray-darker !important;
}

.arbitration-panel {
  position: relative;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.w-100 {
  width: 100%;
}

.justify-center {
  justify-content: center;
}

.tpt-help-center .mat-dialog-container {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
}

.select-panel-custom {
  position: relative;
  top: 52px;
  left: 16px;
  min-width: 100% !important;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.mat-progress-spinner circle {   stroke-linecap: round; }

.detected-link {
  color: $green-main;
  text-decoration: none;

  &:visited {
    color: #551A8B;
  }
}

.mat-button-focus-overlay {
  background-color: transparent !important;
}

.mat-radio-button:not(.mat-radio-disabled).cdk-program-focused .mat-radio-persistent-ripple {
  opacity: 0 !important;
}

.tax-menu {
  position: relative;
  top: 4px;
  max-width: unset !important;
}

.toast-top-right {
  top: 64px
}

.tpt-contract-sign-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.toast-container .ngx-toastr {
  color: #151F2B;
  box-shadow: 0 4px 32px rgba(0, 16, 61, 0.16);
  border-radius: 4px;
  padding: 10px 24px 16px 10px;
  background: $white;
  border-left-width: 2px !important;
  font-size: 14px;
  line-height: 16px;
  width: 388px;
  max-width: calc(100vw - 24px);

  .dark {
    color: $gray-darker;
    font-weight: 500;
  }

  svg {
    flex-shrink: 0;
  }

  &:hover {
    box-shadow: 0 4px 32px rgba(0, 16, 61, 0.16);
  }

  .close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  &.warning {
    border: 1px solid rgba(240, 191, 20, 0.25);
    border-left-color: #F0BF14;

    svg {
      color: #F0BF14;
    }
  }

  &.info {
    border: 1px solid rgba(18, 131, 201, 0.25);
    border-left-color: #1283C9;

    svg {
      color: #1283C9;
    }
  }

  &.success {
    border: 1px solid rgba(35, 161, 71, 0.25);
    border-left-color: #23A147;

    svg {
      color: #23A147;
    }
  }

  &.error {
    border: 1px solid rgba(218, 31, 41, 0.25);
    border-left-color: #DA1F29;

    svg {
      color: #DA1F29;
    }
  }
}

.cdk-overlay-pane.creative-objects {
  .mat-dialog-container {
    position: relative;
    box-shadow: none;
    border-radius: 0;
    padding: 12px 50px 56px;

    .mat-ripple-element {
      background-color: rgba(255, 255, 255, 0.4);
    }

    @include mobile-down {
      padding: 12px 24px 48px;
    }
  }
}

mat-calendar.creative-objects {
  box-shadow: $co-box-shadow !important;

  .mat-calendar-body-selected {
    background-color: $purple-primary !important;
  }

  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(161, 144, 254, 0.3);
  }
}

.creative-objects.mat-select-panel {
  .mat-option.mat-selected:not(.mat-option-disabled) {
    color: $purple-primary;
  }

  .mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
    color: $purple-primary;
  }

  box-shadow: $co-box-shadow !important;
}

.mat-tooltip.purple-bckg {
  color: $white;
  background: #8174CC;
  font-size: 12px;
  padding: 12px 16px;
  width: 250px;
}

.mat-elevation-z4 {
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;
}

.header-profile-menu {
  width: 300px;
  position: relative;
  top: 8px;

}

.mat-slide-toggle-content, .mat-radio-button, .mat-stepper-horizontal, .mat-button, .mat-tab-group, .mat-table,
.mat-tab-label, .mat-option, .mat-card, .mat-menu-item, .mat-checkbox, .mat-toolbar {
  font-family: 'Manrope', 'Inter', sans-serif !important;
}

.mat-calendar-body-cell {
  max-height: 40px;
}

.mat-dialog-container {
  box-shadow: none !important;
}

.mat-tooltip.disabled-button-tooltip {
  color: $white;
  background: $green-dark;
  font-size: 12px;
  padding: 12px 16px;
  width: 250px;
}

.mat-datepicker-content {
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;

  .mat-calendar {
    font-family: 'Manrope', 'Inter', sans-serif !important;
  }

  .mat-calendar-body-cell {
    font-size: 14px;
    font-weight: 600;
  }
}

/*
mat-checkbox
*/

.mat-checkbox {
  .mat-checkbox-frame {
    border-width: 1px !important;
    border-radius: 4px;
    border-color: #D0D5DD;
  }

  .mat-checkbox-ripple {
    display: none;
  }

  .mat-checkbox-background {
    border-radius: 4px;
  }

  .mat-checkbox-layout {
    white-space: normal;
  }

  &.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background,
  &.mat-checkbox-checked.mat-primary .mat-checkbox-background {
    background: #F9F5FF;
    border: 1px solid #59AB8A;
  }

  &.mat-checkbox-indeterminate.mat-primary .mat-checkbox-frame,
  &.mat-checkbox-checked.mat-primary .mat-checkbox-frame {
    border-color: #59AB8A;
  }

  &.mat-checkbox-indeterminate.mat-primary .mat-checkbox-mixedmark,
  &.mat-checkbox-checked.mat-primary .mat-checkbox-mixedmark {
    background-color: #59AB8A;
  }

  &.mat-checkbox-indeterminate.mat-primary .mat-checkbox-checkmark-path,
  &.mat-checkbox-checked.mat-primary .mat-checkbox-checkmark-path {
    stroke: #59AB8A !important;
  }
}


.docs-menu {
  max-width: 360px !important;
}

.autocomplete-panel {
  background: $white;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px -2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px;
  width: 100%;
  max-height: 220px;
  overflow-y: auto;
}

.mat-error, .mat-hint {
  font-size: 12px;
}

.mat-tab-body-content[style*="visibility: hidden"] {
  display: block !important;
}

.mat-tab-body-content {
  visibility: visible !important;
}

.custom-tooltip {
  background: #101828 !important;
  font-family: 'Manrope', 'Inter', sans-serif !important;
  font-weight: 600;
  font-size: 12px !important;
  max-width: 350px !important;
}

.app-add-executor-dialog .mat-dialog-container {
  display: flex;
  flex-direction: column;
}

.red-asterisk {
  color: #DB495C;
}

.overflow-hidden {
  overflow: hidden;
}

.emp-task-details-dialog, .fr-task-details-dialog {
  .mat-dialog-container {
    @include mobile-down {
      padding: 16px;
    }
  }
}

.mat-menu-panel.menu-dark-tooltip {
  padding: 8px 12px;
  border-radius: 8px;
  background: $gray-900;
  left: 50%;
  min-height: 40px !important;
  bottom: 8px;

  .mat-menu-content {
    padding: 0;
  }
}

.cursor-pointer {
  cursor: pointer;
}
