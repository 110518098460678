@import "~nouislider/distribute/nouislider.min.css";
@import "variables";

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -12px !important;
}

.noUi-horizontal/*.theme-common */{
  height: 5px;
  border: none;
  box-shadow: none;

  .noUi-handle {
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    width: 16px;
    height: 16px;
    outline: none;
  }

  .noUi-handle:after, .noUi-handle:before {
    width: 0;
  }

  .noUi-tooltip {
    top: 120%;
    bottom: inherit;
    font-size: 70%;
    border: none;
    background: none;
  }
}

.noUi-top-label {
  .noUi-horizontal .noUi-tooltip {
    top: -26px;
    bottom: initial;
  }
}


