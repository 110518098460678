@import 'fonts';
/* You can add global styles to this file, and also import other style files */
/* import fonts start*/
/*@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&subset=cyrillic,cyrillic-ext');*/
/* import fonts  end*/
/* reset styles start*/
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

@import 'fullcalendar/dist/fullcalendar.min.css';
@import "./theme.scss";
@import "spacing";
@import "font-styles";
@import 'ngx-toastr/toastr';
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    //background-color: rgba(0, 0, 0, 0.02);
  background: #fff;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote {
    &:before,
    &:after {
        content: '';
        content: none;
    }
}

q {
    &:before,
    &:after {
        content: '';
        content: none;
    }
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* reset styles end */

body,
html {
    height: 100%;
}

* {
    box-sizing: border-box;
}

body {
    font-family: 'Manrope', 'Inter', Roboto, 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;

  app-co-root {
    font-family: Roboto, 'Helvetica Neue', sans-serif;

  }
}

.expansion-custom-header > .mat-expansion-panel-header {
    padding: 0;
}

.js-expansion-header > .mat-content {
    height: 100%;
}

/*fix migrate to beta 10*/
.expansion-custom-header > .mat-content {
    height: 100%;
    align-items: center;
}

/*fix migrate to beta 10*/
.mat-button-toggle-checked {
    color: rgba(0, 0, 0, 0.87);
}

/*fix migrate to beta 10*/
mat-card-footer.mat-card-footer {
    margin: 0;
}

.project-dialog__input-container .mat-select-disabled .mat-select-value {
    color: inherit;
}

div.wiki pre {
    margin: 1em 1em 1em 1.6em;
    padding: 8px;
    background-color: #fafafa;
    border: 1px solid #e2e2e2;
    width: auto;
    overflow-x: auto;
    overflow-y: hidden;
}

div.wiki {
    ul {
        display: block;
        list-style-type: disc;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-top: 1em;
        margin-bottom: 1em;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
    }

    sub {
        vertical-align: sub;
        font-size: smaller;
    }

    sup {
        vertical-align: super;
        font-size: smaller;
    }

    code {
        font-family: monospace;
    }

    li {
        display: list-item;
    }
}

.mat-card-header-text {
    width: 100%;
}

.mat-expansion-panel-body {
    max-height: 100%;
    height: 100%;
}

.inline-select .mat-select-trigger {
    height: 100%;
    padding-right: 4px;
}

h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
}

h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
}

h4 {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #575b5f !important;
}

@font-face {
    font-family: 'fontello';
    src: url('fonts/fontello.eot?52411374');
    src: url('fonts/fontello.eot?52411374#iefix') format('embedded-opentype'),
        url('fonts/fontello.woff2?52411374') format('woff2'), url('fonts/fontello.woff?52411374') format('woff'),
        url('fonts/fontello.ttf?52411374') format('truetype'), url('fonts/fontello.svg?52411374#fontello') format('svg');
    font-weight: normal;
    font-style: normal;
}

@media (max-width: 767px) {
    h1 {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
    }
}
@import 'fonts';
@import 'css/material';
@import 'css/noUi-slider';
@import 'styles/header/header';
@import 'styles/pages/pages';
